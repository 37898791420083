import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import * as tf from '@tensorflow/tfjs'; // TensorFlow.js

const ObjectDetection = () => {
    const [model, setModel] = useState(null);
    const [detections, setDetections] = useState([]);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    // Load the pre-trained YOLOv11 model (or YOLOv8 as it's similar in approach)
    useEffect(() => {
        const loadModel = async () => {
            try {
                const loadedModel = await tf.loadGraphModel('https://path-to-yolo-model/model.json'); // Replace with actual path
                setModel(loadedModel);
            } catch (error) {
                console.error("Error loading YOLO model:", error);
            }
        };
        loadModel();
    }, []);

    // Function to detect objects using the YOLO model
    const detectObjects = async () => {
        if (model && videoRef.current && videoRef.current.video.readyState === 4) {
            const video = videoRef.current.video;

            // Get a TensorFlow tensor from the video element
            const inputTensor = tf.browser.fromPixels(video).resizeBilinear([640, 480]).expandDims(0).div(255);

            // Perform inference with YOLO model
            const predictions = await model.executeAsync(inputTensor);

            // Process predictions (you will need to interpret YOLO's output format here)
            const processedDetections = processDetections(predictions);

            // Draw bounding boxes
            drawBoundingBoxes(processedDetections);

            // Set detected objects for state
            setDetections(processedDetections);

            tf.dispose(inputTensor); // Clean up memory
        }
    };

    // Convert YOLO's predictions to a more usable format for drawing bounding boxes
    const processDetections = (predictions) => {
        // Process the YOLO predictions here
        // You will need to convert the tensor to a usable format, and filter out low confidence detections
        return [];
    };

    // Draw bounding boxes on the canvas
    const drawBoundingBoxes = (detections) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = 640;
        canvas.height = 480;

        // Clear previous frames
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw the new bounding boxes
        detections.forEach(detection => {
            const [x, y, width, height] = detection.bbox;
            ctx.strokeStyle = '#0F0';
            ctx.lineWidth = 2;
            ctx.strokeRect(x, y, width, height);
        });
    };

    // Run detection every time the video frame updates
    useEffect(() => {
        const intervalId = setInterval(detectObjects, 100); // Detect every 100ms
        return () => clearInterval(intervalId); // Clean up on component unmount
    }, [model]);

    return (
        <div>
            <Webcam 
                ref={videoRef} 
                audio={false} 
                videoConstraints={{
                    facingMode: { exact: "environment" }, // Back-facing camera
                    width: 640, 
                    height: 480
                }}
                style={{ width: 640, height: 480 }} 
            />
            <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0 }} />
        </div>
    );
};

export default ObjectDetection;