import React, { useState, useRef, useEffect } from 'react';
import { ShoppingCart, Home, User, Search, X, ListChecks, Navigation, ArrowLeft, Star, PlusCircle, Check, ChevronRight, ChevronLeft, MapPin, CreditCard, Smartphone } from 'lucide-react';
import placeholder100 from '../assets/images/placeholders/100.png';
import placeholder300 from '../assets/images/placeholders/300.png';
import ProductDetectionComponent from '../components/ProductDetectionComponent';

const SmartCart = () => {
  const [activeTab, setActiveTab] = useState('debug');
  const [activeList, setActiveList] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [scannedProduct, setScannedProduct] = useState(null);
  const searchInputRef = useRef(null);
  const [products, setProducts] = useState([
    { id: 1, name: 'Wireless Earbuds', price: 79.99, quantity: 1, image: placeholder100 },
    { id: 2, name: 'Smart Watch', price: 199.99, quantity: 1, image: placeholder100 },
    { id: 3, name: 'Portable Charger', price: 49.99, quantity: 2, image: placeholder100 },
  ]);
  const [smartLists, setSmartLists] = useState([
    { id: 1, name: 'Groceries', items: ['Milk', 'Bread', 'Eggs', 'Vegetables'], createdAt: '2023-09-20' },
    { id: 2, name: 'Electronics', items: ['HDMI Cable', 'USB-C Charger', 'Wireless Mouse'], createdAt: '2023-09-22' },
    { id: 3, name: 'Home Decor', items: ['Throw Pillows', 'Wall Clock', 'Picture Frames'], createdAt: '2023-09-25' },
  ]);
  const [shelfProducts, setShelfProducts] = useState([
    { id: 4, name: 'Organic Milk', price: 3.99, image: placeholder100 },
    { id: 5, name: 'Whole Grain Bread', price: 2.49, image: placeholder100 },
    { id: 6, name: 'Free-Range Eggs', price: 4.99, image: placeholder100 },
  ]);
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchOverlay, setShowSearchOverlay] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showAddToCartOverlay, setShowAddToCartOverlay] = useState(false);
  const [highlightedProductId, setHighlightedProductId] = useState(null);
  const [showStoreNavigation, setShowStoreNavigation] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({ x: 50, y: 90 });
  const [targetLocation, setTargetLocation] = useState(null);
  const [lastKeyPress, setLastKeyPress] = useState('');
  const [keyPressBuffer, setKeyPressBuffer] = useState('');
  const [showCheckout, setShowCheckout] = useState(false);
  const [showPairingScreen, setShowPairingScreen] = useState(true);
  const [cvLoaded, setCvLoaded] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (showPairingScreen) {
      const timer = setTimeout(() => {
        setShowPairingScreen(false);
      }, 5000); // 5 seconds delay
      return () => clearTimeout(timer);
    }
  }, [showPairingScreen]);

  const renderCheckout = () => (
    <div className="fixed inset-0 bg-white z-50 overflow-y-auto">
      <div className="max-w-3xl mx-auto p-6">
        <h2 className="text-2xl font-semibold mb-6">Checkout</h2>
        <div className="bg-gray-100 rounded-lg p-4 mb-6">
          <h3 className="text-lg font-semibold mb-2">Invoice</h3>
          {products.map((product) => (
            <div key={product.id} className="flex justify-between items-center py-2 border-b last:border-b-0">
              <span>{product.name} x {product.quantity}</span>
              <span>${(product.price * product.quantity).toFixed(2)}</span>
            </div>
          ))}
          <div className="flex justify-between items-center mt-4 font-semibold">
            <span>Total</span>
            <span>${totalPrice.toFixed(2)}</span>
          </div>
        </div>
        <div className="text-center">
          <CreditCard size={48} className="mx-auto mb-4 text-blue-500" />
          <p className="text-xl font-semibold mb-2">Please scan your card to finalize your purchase</p>
          <p className="text-red-500 font-bold text-lg">WARNING: Don't remove items from the cart yet!</p>
        </div>
        <button
          onClick={() => setShowCheckout(false)}
          className="mt-8 w-full bg-blue-500 text-white py-3 rounded-lg font-semibold hover:bg-blue-600 transition duration-300"
        >
          Back to Cart
        </button>
      </div>
    </div>
  );

  const renderPairingScreen = () => (
    <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="text-center">
        <Smartphone size={64} className="mx-auto mb-4 text-blue-500" />
        <h2 className="text-2xl font-semibold mb-4">Pair Your Phone</h2>
        <p className="text-gray-600 mb-4">Please scan the QR code on the cart to pair your phone.</p>
        <div className="animate-pulse">
          <div className="w-32 h-32 bg-gray-300 mx-auto rounded-lg"></div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const handleKeyPress = (event) => {
      setLastKeyPress(event.key);
      setKeyPressBuffer(prev => (prev + event.key).slice(-5));
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (keyPressBuffer === 'cart!') {
      simulateAddToCart();
      setKeyPressBuffer('');
    }
  }, [keyPressBuffer]);

  const simulateAddToCart = () => {
    const randomProduct = {
      id: Math.floor(Math.random() * 1000) + 200,
      name: `Random Product ${Math.floor(Math.random() * 100)}`,
      price: parseFloat((Math.random() * 50 + 5).toFixed(2)),
      quantity: 1,
      image: placeholder100
    };

    addSearchResultToCart(randomProduct);
  };

  const searchForItem = (item) => {
    // Simulated search results
    const results = [
      { id: 101, name: item, description: 'High quality ' + item, price: 9.99, image: placeholder100, location: 'Aisle 3, Shelf 2' },
      { id: 102, name: 'Premium ' + item, description: 'Luxury version of ' + item, price: 19.99, image: placeholder100, location: 'Aisle 5, Shelf 1' },
      { id: 103, name: 'Budget ' + item, description: 'Affordable ' + item, price: 5.99, image: placeholder100, location: 'Aisle 1, Shelf 4' },
    ];
    setSearchResults(results);
    setShowSearchOverlay(true);
    setSelectedProduct(null);
  };

  // Updated addSearchResultToCart function
  const addSearchResultToCart = (product) => {
    setShowAddToCartOverlay(true);

    setTimeout(() => {
      const existingProduct = products.find(p => p.id === product.id);
      if (existingProduct) {
        setProducts(products.map(p =>
          p.id === product.id ? { ...p, quantity: p.quantity + 1 } : p
        ));
      } else {
        setProducts([...products, { ...product, quantity: 1 }]);
      }

      setTimeout(() => {
        setShowAddToCartOverlay(false);
        setShowSearchOverlay(false);
        setSelectedProduct(null);
        setActiveTab('cart');

        setTimeout(() => {
          setHighlightedProductId(product.id);
          setTimeout(() => setHighlightedProductId(null), 1000);
        }, 100);
      }, 1000);
    }, 500);
  };

  const openProductDetail = (product) => {
    setSelectedProduct(product);
  };

  const closeProductDetail = () => {
    setSelectedProduct(null);
  };

  const navigateToProduct = (location) => {
    setShowSearchOverlay(false);
    setSelectedProduct(null);
    setActiveTab('storeNavigation');
    setShowStoreNavigation(true);
    setTargetLocation(parseLocation(location));
  };

  const parseLocation = (locationString) => {
    const [aisle, shelf] = locationString.split(', ');
    const aisleNum = parseInt(aisle.split(' ')[1]);
    const shelfNum = parseInt(shelf.split(' ')[1]);
    return { x: aisleNum * 20, y: shelfNum * 20 };
  };

  const totalPrice = products.reduce((sum, product) => sum + product.price * product.quantity, 0);

  useEffect(() => {
    if (showSearch && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showSearch]);

  const handleClickOutside = (event) => {
    if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
      setShowSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const removeProduct = (id) => {
    setProducts(products.filter(product => product.id !== id));
  };

  const nextList = () => {
    setActiveList((prev) => (prev + 1) % smartLists.length);
  };

  const prevList = () => {
    setActiveList((prev) => (prev - 1 + smartLists.length) % smartLists.length);
  };

  const addToCart = (product) => {
    setScannedProduct(product);
    setShowOverlay(true);

    setTimeout(() => {
      const existingProduct = products.find(p => p.id === product.id);
      if (existingProduct) {
        setProducts(products.map(p =>
          p.id === product.id ? { ...p, quantity: p.quantity + 1 } : p
        ));
      } else {
        setProducts([...products, { ...product, quantity: 1 }]);
      }
      setShelfProducts(shelfProducts.filter(p => p.id !== product.id));

      setTimeout(() => {
        setShowOverlay(false);
        setScannedProduct(null);
      }, 1500); // Fade out after 1.5 seconds
    }, 2000); // Add to cart after 2 seconds
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'cart':
        return (
          <div className="bg-white rounded-lg shadow-md p-6">
            {products.map((product) => (

              <div
                key={product.id}
                className={`flex justify-between items-center py-4 border-b last:border-b-0 ${highlightedProductId === product.id ? 'bg-yellow-100 transition-colors duration-1000' : ''
                  }`}
              >
                <div className="flex items-center">
                  <img src={product.image} alt={product.name} className="w-16 h-16 object-cover rounded mr-4" />
                  <div>
                    <h3 className="font-medium text-gray-800">{product.name}</h3>
                    <p className="text-sm text-gray-500">Quantity: {product.quantity}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <span className="font-semibold text-gray-800 mr-4">${(product.price * product.quantity).toFixed(2)}</span>
                  <button onClick={() => removeProduct(product.id)} className="text-red-500 hover:text-red-700">
                    <X size={20} />
                  </button>
                </div>
              </div>
            ))}
            <div className="mt-6 flex justify-between items-center">
              <span className="text-lg font-semibold text-gray-800">Total</span>
              <span className="text-2xl font-bold text-blue-600">${totalPrice.toFixed(2)}</span>
            </div>
            <button
              onClick={() => setShowCheckout(true)} className="mt-6 w-full bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition duration-300">
              Proceed to Checkout
            </button>
          </div>
        );
      case 'smartLists':
        return (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Smart Lists</h2>
            <div className="flex items-center justify-between mb-6">
              <button onClick={prevList} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300">
                <ChevronLeft size={24} />
              </button>
              <div className="flex-1 mx-4 overflow-x-auto">
                <div className="flex space-x-4">
                  {smartLists.map((list, index) => (
                    <div
                      key={list.id}
                      className={`flex-shrink-0 w-48 p-4 border rounded-lg cursor-pointer ${index === activeList ? 'border-blue-500 bg-blue-50' : 'border-gray-200'}`}
                      onClick={() => setActiveList(index)}
                    >
                      <h3 className="font-medium text-gray-800 mb-2">{list.name}</h3>
                      <p className="text-sm text-gray-500 mb-2">{list.items.slice(0, 2).join(', ')}...</p>
                      <p className="text-xs text-gray-400">Created: {list.createdAt}</p>
                    </div>
                  ))}
                </div>
              </div>
              <button onClick={nextList} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300">
                <ChevronRight size={24} />
              </button>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <h3 className="font-medium text-gray-800 mb-2">{smartLists[activeList].name}</h3>
              <ul className="list-disc pl-5">
                {smartLists[activeList].items.map((item, index) => (
                  <li key={index} className="text-gray-600 mb-2 last:mb-0">
                    <div className="flex justify-between items-center">
                      <span>{item}</span>
                      <button
                        onClick={() => searchForItem(item)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Search for item
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      case 'storeNavigation':
        return showStoreNavigation ? renderStoreNavigation() : renderDefaultStoreNavigation();
      case 'debug':
        return (
          <div>
            <h2>Debug Mode: Optical Flow and Object Detection</h2>
            <ProductDetectionComponent />
          </div>
        );
      default:
        return null;
    }
  };

  const renderAdsBar = () => (
    <aside className="w-64 bg-white p-4 overflow-y-auto">
      <h2 className="text-xl font-semibold mb-4">Ads</h2>
      <div className="space-y-4">
        <div className="rounded-lg overflow-hidden shadow-md">
          <img src={placeholder300} alt="Special Offer 1" className="w-full h-40 object-cover" />
          <div className="p-4">
            <h3 className="font-semibold text-gray-800">Summer Sale!</h3>
            <p className="text-sm text-gray-600 mt-1">Up to 50% off on summer essentials</p>
          </div>
        </div>
        <div className="rounded-lg overflow-hidden shadow-md">
          <img src={placeholder300} alt="Special Offer 2" className="w-full h-40 object-cover" />
          <div className="p-4">
            <h3 className="font-semibold text-gray-800">New Arrivals</h3>
            <p className="text-sm text-gray-600 mt-1">Check out our latest products</p>
          </div>
        </div>
      </div>
    </aside>
  ); // Add this new function to render the search overlay and product detail

  const renderProfile = () => (
    <div className="bg-white h-full p-6">
      <button onClick={() => setShowProfile(false)} className="flex items-center text-blue-600 mb-6">
        <ArrowLeft size={20} className="mr-2" /> Back
      </button>
      <div className="flex items-center mb-6">
        <img src="/api/placeholder/100/100" alt="Profile" className="w-20 h-20 rounded-full mr-4" />
        <div>
          <h2 className="text-2xl font-semibold">John Doe</h2>
          <div className="flex items-center mt-1">
            <Star className="text-yellow-400 mr-1" size={16} />
            <span className="text-gray-600">4.8</span>
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <div>
          <h3 className="font-medium text-gray-800">Email</h3>
          <p className="text-gray-600">john.doe@example.com</p>
        </div>
        <div>
          <h3 className="font-medium text-gray-800">Phone</h3>
          <p className="text-gray-600">+1 (555) 123-4567</p>
        </div>
        <div>
          <h3 className="font-medium text-gray-800">Address</h3>
          <p className="text-gray-600">123 Main St, Anytown, USA 12345</p>
        </div>
      </div>
    </div>
  );

  const renderStoreNavigation = () => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4">Store Navigation</h2>
      <div className="relative w-full h-80 bg-gray-200 border border-gray-300">
        {/* Simplified store layout */}
        <div className="absolute top-0 left-0 w-full h-full grid grid-cols-5 gap-2 p-2">
          {[...Array(25)].map((_, index) => (
            <div key={index} className="bg-gray-300"></div>
          ))}
        </div>
        {/* Target location */}
        {targetLocation && (
          <div
            className="absolute w-4 h-4 bg-red-500 rounded-full"
            style={{ left: `${targetLocation.x}%`, top: `${targetLocation.y}%` }}
          ></div>
        )}
        {/* Current location */}
        <div
          className="absolute w-4 h-4 bg-blue-500 rounded-full"
          style={{ left: `${currentLocation.x}%`, top: `${currentLocation.y}%` }}
        ></div>
        {/* Simple path */}
        {targetLocation && (
          <svg className="absolute top-0 left-0 w-full h-full">
            <line
              x1={`${currentLocation.x}%`}
              y1={`${currentLocation.y}%`}
              x2={`${targetLocation.x}%`}
              y2={`${targetLocation.y}%`}
              stroke="green"
              strokeWidth="2"
            />
          </svg>
        )}
      </div>
      <button
        onClick={() => setShowStoreNavigation(false)}
        className="mt-4 w-full bg-blue-500 text-white py-2 rounded-lg font-semibold hover:bg-blue-600 transition duration-300"
      >
        Exit Navigation
      </button>
    </div>
  );

  const renderDefaultStoreNavigation = () => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4">Store Navigation</h2>
      <p>Select a product to navigate to its location in the store.</p>
    </div>
  );

  // Update the renderSearchOverlay function
  const renderSearchOverlay = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        {selectedProduct ? (
          <div>
            <button
              onClick={closeProductDetail}
              className="mb-4 text-blue-500 hover:text-blue-700 flex items-center"
            >
              <ArrowLeft size={20} className="mr-2" /> Go back
            </button>
            <img src={selectedProduct.image} alt={selectedProduct.name} className="w-full h-48 object-cover rounded mb-4" />
            <h3 className="text-xl font-semibold mb-2">{selectedProduct.name}</h3>
            <p className="text-gray-600 mb-2">{selectedProduct.description}</p>
            <p className="text-lg font-semibold mb-4">${selectedProduct.price.toFixed(2)}</p>
            <button
              onClick={() => navigateToProduct(selectedProduct.location)}
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center justify-center"
            >
              <MapPin size={20} className="mr-2" /> Navigate to Item
            </button>
          </div>
        ) : (
          <>
            <h3 className="text-xl font-semibold mb-4">Search Results</h3>
            <div className="space-y-4">
              {searchResults.map((product) => (
                <div key={product.id} className="flex items-center bg-gray-100 p-3 rounded-lg cursor-pointer" onClick={() => openProductDetail(product)}>
                  <img src={product.image} alt={product.name} className="w-16 h-16 object-cover rounded mr-4" />
                  <div className="flex-grow">
                    <h4 className="font-medium">{product.name}</h4>
                    <p className="text-sm text-gray-600">{product.description}</p>
                    <p className="text-sm font-semibold mt-1">${product.price.toFixed(2)}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <button
          onClick={() => {
            setShowSearchOverlay(false);
            setSelectedProduct(null);
          }}
          className="mt-4 w-full bg-gray-200 text-gray-800 py-2 rounded-lg font-semibold hover:bg-gray-300 transition duration-300"
        >
          Close
        </button>
      </div>
    </div>
  );

  // Add this new function to render the add to cart overlay
  const renderAddToCartOverlay = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <div className="animate-bounce mb-4">
          <Check className="text-green-500 mx-auto" size={48} />
        </div>
        <p className="text-xl font-semibold">Item added to cart!</p>
      </div>
    </div>
  );

  return (
    <div className="flex h-screen bg-gray-100 relative">
      {showPairingScreen && renderPairingScreen()}
      <div className="flex-grow flex flex-col">
        {/* Top Navigation */}
        <header className="bg-white shadow-sm p-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-800">SmartShop</h1>
          <div className="flex items-center space-x-4">
            {showSearch ? (
              <input
                ref={searchInputRef}
                type="text"
                placeholder="Search..."
                className="border rounded-lg px-3 py-1"
              />
            ) : (
              <button onClick={() => setShowSearch(true)} className="text-gray-500 hover:text-gray-700">
                <Search size={24} />
              </button>
            )}
            <button onClick={() => setShowProfile(true)} className="text-gray-500 hover:text-gray-700">
              <User size={24} />
            </button>
          </div>
        </header>

        {/* Main Content */}
        <main className="flex-grow overflow-y-auto p-6 space-y-6">
          {showProfile ? (
            <div>Profile Details</div>
          ) : (
            <>
              <h2 className="text-2xl font-semibold text-gray-800">
                {activeTab === 'cart'
                  ? 'Your Cart'
                  : activeTab === 'smartLists'
                    ? 'Smart Lists'
                    : activeTab === 'storeNavigation'
                      ? 'Store Navigation'
                      : 'Debug Mode'}
              </h2>
              {renderTabContent()}
            </>
          )}
        </main>

        {/* Bottom Navigation */}
        <nav className="bg-white shadow-md">
          <ul className="flex justify-around py-4">
            <li>
              <button
                onClick={() => setActiveTab('smartLists')}
                className={`flex flex-col items-center ${activeTab === 'smartLists' ? 'text-blue-600' : 'text-gray-600'}`}
              >
                <ListChecks size={24} />
                <span className="text-xs mt-1">Smart Lists</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => setActiveTab('cart')}
                className={`flex flex-col items-center ${activeTab === 'cart' ? 'text-blue-600' : 'text-gray-600'}`}
              >
                <ShoppingCart size={24} />
                <span className="text-xs mt-1">Cart</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => setActiveTab('storeNavigation')}
                className={`flex flex-col items-center ${activeTab === 'storeNavigation' ? 'text-blue-600' : 'text-gray-600'}`}
              >
                <Navigation size={24} />
                <span className="text-xs mt-1">Store</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => setActiveTab('debug')}
                className={`flex flex-col items-center ${activeTab === 'debug' ? 'text-blue-600' : 'text-gray-600'}`}
              >
                <span className="text-xs mt-1">Debug</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {renderAdsBar()}
      {/* Scanning Overlay */}
      {showOverlay && scannedProduct && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <img src={scannedProduct.image} alt={scannedProduct.name} className="w-32 h-32 object-cover rounded mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">{scannedProduct.name}</h3>
            <p className="text-gray-600 mb-4">${scannedProduct.price.toFixed(2)}</p>
            <div className="animate-bounce">
              <Check className="text-green-500 mx-auto" size={48} />
            </div>
          </div>
        </div>
      )}
      {showSearchOverlay && renderSearchOverlay()}
      {showAddToCartOverlay && renderAddToCartOverlay()}
      {showCheckout && renderCheckout()}
      <div className="fixed bottom-0 right-0 p-2 text-xs text-gray-400">
        Last key: {lastKeyPress}
      </div>
    </div>
  );
};

export default SmartCart;