import React, { useEffect, useRef, useState, useCallback } from 'react';
import VideoCaptureComponent from './VideoCaptureComponent';
import { calculateOpticalFlow, analyzeFlow, drawOpticalFlow, useDebugInfo } from './opticalFlowAnalysis';

const ProductDetectionComponent = () => {
    const canvasRef = useRef(null);
    const debugCanvasRef = useRef(null);
    const prevGrayCanvasRef = useRef(null);
    const currGrayCanvasRef = useRef(null);
    const [previousGray, setPreviousGray] = useState(null);
    const [cvReady, setCvReady] = useState(false);
    const [debugInfo, setDebugInfo] = useState({ avgMagnitude: 0, maxMagnitude: 0, motionDetected: false });
    const [flowData, setFlowData] = useState([]);
    const logDebugInfo = useDebugInfo();

    useEffect(() => {
        if (window.cv) {
            setCvReady(true);
        } else {
            const script = document.createElement('script');
            script.src = 'https://docs.opencv.org/4.5.2/opencv.js';
            script.async = true;
            script.onload = () => setCvReady(true);
            document.body.appendChild(script);
        }

        return () => {
            if (previousGray) {
                previousGray.delete();
            }
        };
    }, []);

    const onFrameCapture = useCallback((capturedCanvas) => {
        if (!cvReady || !capturedCanvas || capturedCanvas.width === 0 || capturedCanvas.height === 0) {
            console.error("OpenCV not ready or invalid captured canvas:", { cvReady, capturedCanvas });
            return;
        }

        const canvas = canvasRef.current;
        const debugCanvas = debugCanvasRef.current;
        if (!canvas || !debugCanvas) {
            console.error("Main or debug canvas not ready");
            return;
        }

        let src, gray, flow;

        try {
            // Setup canvases
            const context = canvas.getContext('2d');
            const debugContext = debugCanvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
            debugContext.clearRect(0, 0, debugCanvas.width, debugCanvas.height);
            context.drawImage(capturedCanvas, 0, 0, canvas.width, canvas.height);

            // Process image
            try {
                src = window.cv.imread(capturedCanvas);
                if (!src || src.empty()) throw new Error("Source image is null or empty");

                console.log("Source image details:", {
                    width: src.cols,
                    height: src.rows,
                    channels: src.channels(),
                    type: src.type()
                });
            } catch (error) {
                console.error("Error reading source image:", error);
                return;
            }

            try {
                gray = new window.cv.Mat();
                window.cv.cvtColor(src, gray, window.cv.COLOR_RGBA2GRAY);

                console.log("Grayscale image details:", {
                    width: gray.cols,
                    height: gray.rows,
                    channels: gray.channels(),
                    type: gray.type()
                });
            } catch (error) {
                console.error("Error converting to grayscale:", error);
                return;
            }

            // Handle first frame
            if (!previousGray || previousGray.empty()) {
                console.log("Setting previousGray for the first time");
                setPreviousGray(gray.clone());
                return;
            }

            // Validate frames
            try {
                console.log("Previous Gray details:", {
                    width: previousGray.cols,
                    height: previousGray.rows,
                    channels: previousGray.channels(),
                    type: previousGray.type(),
                    empty: previousGray.empty(),
                    size: previousGray.size(),
                });
                console.log("Current Gray details:", {
                    width: gray.cols,
                    height: gray.rows,
                    channels: gray.channels(),
                    type: gray.type(),
                    empty: gray.empty(),
                    size: gray.size(),
                });

                if (previousGray.rows !== gray.rows || previousGray.cols !== gray.cols) {
                    throw new Error("Mismatched dimensions in optical flow calculation");
                }
            } catch (error) {
                console.error("Error validating frames:", error);
                return;
            }

            // Calculate optical flow
            try {
                console.log("Before optical flow calculation:", {
                    previousGrayDims: { rows: previousGray.rows, cols: previousGray.cols },
                    grayDims: { rows: gray.rows, cols: gray.cols }
                });
                flow = calculateOpticalFlow(previousGray, gray);
                console.log("After optical flow calculation:", {
                    flowDims: flow ? { rows: flow.rows, cols: flow.cols } : 'Flow is null'
                });
            } catch (error) {
                console.error("Error in optical flow calculation:", error);
                return;
            }

            // Analyze flow
            let motionDetected, avgMagnitude, maxMagnitude;
            try {
                ({ motionDetected, avgMagnitude, maxMagnitude } = analyzeFlow(flow, debugCanvas, setFlowData));
            } catch (error) {
                console.error("Error in flow analysis:", error);
                return;
            }

            // Update UI
            debugContext.drawImage(canvas, 0, 0, debugCanvas.width, debugCanvas.height);
            const newDebugInfo = { avgMagnitude, maxMagnitude, motionDetected };
            setDebugInfo(newDebugInfo);
            logDebugInfo(newDebugInfo);

            if (motionDetected) {
                console.log("Product detected in cart");
                // Implement your product drop logic here
            }

            // Prepare for next frame
            try {
                if (previousGray && !previousGray.isDeleted()) previousGray.delete();
                setPreviousGray(gray.clone());
                console.log("New previousGray set:", {
                    width: gray.cols,
                    height: gray.rows,
                    channels: gray.channels(),
                    type: gray.type()
                });
            } catch (error) {
                console.error("Error preparing for next frame:", error);
            }
        } catch (error) {
            console.error("Unexpected error in frame processing:", error);
        } finally {
            // Cleanup
            [src, flow, gray].forEach(mat => {
                if (mat && !mat.isDeleted()) {
                    try {
                        mat.delete();
                    } catch (error) {
                        console.error("Error deleting matrix:", error);
                    }
                }
            });
        }
    }, [cvReady, previousGray, logDebugInfo, calculateOpticalFlow, analyzeFlow]);

    const renderDebugInfo = () => (
        <div style={{ position: 'absolute', top: 10, left: 10, background: 'rgba(0,0,0,0.7)', color: 'white', padding: '10px' }}>
            <h3>Debug Info</h3>
            <div>Average Magnitude: {debugInfo.avgMagnitude.toFixed(2)}</div>
            <div>Max Magnitude: {debugInfo.maxMagnitude.toFixed(2)}</div>
            <div>Motion Detected: {debugInfo.motionDetected ? 'Yes' : 'No'}</div>
        </div>
    );

    const renderFlowData = () => (
        <div style={{ position: 'absolute', top: 60, left: 10, color: 'white', padding: '10px', maxHeight: '200px', overflowY: 'auto' }}>
            {flowData.slice(0, 10).map((data, index) => (
                <div key={index}>
                    X: {data.x}, Y: {data.y}, FlowX: {data.flowX.toFixed(2)}, FlowY: {data.flowY.toFixed(2)}, Magnitude: {data.magnitude.toFixed(2)}
                </div>
            ))}
        </div>
    );

    if (!cvReady) return <div>Loading OpenCV...</div>;

    return (
        <div style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
            <VideoCaptureComponent onFrameCapture={onFrameCapture} />
            <canvas ref={canvasRef} width={640} height={480} style={{ border: '1px solid black' }} />
            <canvas ref={debugCanvasRef} width={640} height={480} style={{ border: '1px solid black', marginLeft: '640px' }} />
            <canvas ref={prevGrayCanvasRef} width={640} height={480} style={{ border: '1px solid black', marginTop: '480px' }} />
            <canvas ref={currGrayCanvasRef} width={640} height={480} style={{ border: '1px solid black', marginTop: '480px', marginLeft: '640px' }} />
            {renderDebugInfo()}
            {renderFlowData()}
        </div>
    );
};

export default ProductDetectionComponent;