import { useRef } from 'react';

export const calculateOpticalFlow = (prevFrame, currFrame) => {
    const flow = new window.cv.Mat();
    window.cv.calcOpticalFlowFarneback(prevFrame, currFrame, flow, 0.5, 3, 15, 3, 5, 1.2, 0);
    return flow;
};

export const analyzeFlow = (flow, canvas, setFlowData) => {
    const cols = flow.cols;
    const rows = flow.rows;
    let sumMagnitude = 0;
    let maxMagnitude = 0;
    const threshold = 1.0;
    const flowArray = [];

    for (let y = 0; y < rows; y++) {
        for (let x = 0; x < cols; x++) {
            const flowData = flow.data32F;
            const index = (y * cols + x) * 2;
            const fx = flowData[index];
            const fy = flowData[index + 1];
            const magnitude = Math.sqrt(fx * fx + fy * fy);

            sumMagnitude += magnitude;
            maxMagnitude = Math.max(maxMagnitude, magnitude);

            // Collect flow data for rendering
            flowArray.push({
                x: x,
                y: y,
                flowX: fx,
                flowY: fy,
                magnitude: magnitude
            });
        }
    }

    const avgMagnitude = sumMagnitude / (cols * rows);
    const motionDetected = avgMagnitude > threshold;

    // Update the flow data state for rendering
    setFlowData(flowArray);

    return { motionDetected, avgMagnitude, maxMagnitude };
};

export const drawOpticalFlow = (flow, canvas) => {
    const ctx = canvas.getContext('2d');
    const cols = flow.cols;
    const rows = flow.rows;
    const step = 16;

    for (let y = 0; y < rows; y += step) {
        for (let x = 0; x < cols; x += step) {
            const flowData = flow.data32F;
            const index = (y * cols + x) * 2;
            const fx = flowData[index];
            const fy = flowData[index + 1];

            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(x + fx, y + fy);
            ctx.strokeStyle = 'red';
            ctx.stroke();
        }
    }
};

export const useDebugInfo = () => {
    const debugInfoRef = useRef({ frameCount: 0, lastLogTime: 0 });
    
    const logDebugInfo = (info) => {
        debugInfoRef.current.frameCount++;
        const now = performance.now();
        if (now - debugInfoRef.current.lastLogTime > 1000) { // Log every second
            console.log(`Frames processed: ${debugInfoRef.current.frameCount}, FPS: ${debugInfoRef.current.frameCount / ((now - debugInfoRef.current.lastLogTime) / 1000)}`);
            console.log(`Last frame info:`, info);
            debugInfoRef.current.frameCount = 0;
            debugInfoRef.current.lastLogTime = now;
        }
    };

    return logDebugInfo;
};
