import React, { useEffect, useRef, useState } from 'react';

const VideoCaptureComponent = ({ onFrameCapture }) => {
  const videoRef = useRef(null);
  const [videoStream, setVideoStream] = useState(null);

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: { facingMode: { ideal: "environment" } } })
        .then(stream => {
          setVideoStream(stream);
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
          }
        })
        .catch(err => console.error("Error accessing camera:", err));
    }

    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const captureFrame = () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0);
        onFrameCapture(canvas);
      }
      requestAnimationFrame(captureFrame);
    };

    video.addEventListener('play', captureFrame);

    return () => {
      video.removeEventListener('play', captureFrame);
    };
  }, [onFrameCapture]);

  return <video ref={videoRef} style={{ display: 'none' }} />;
};

export default VideoCaptureComponent;