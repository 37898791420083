import React from 'react';
import ObjectDetection from './components/ObjectDetection';
import SmartCart from './screens/SmartCart';

function App() {
  return (
    <div className="App">
      <h1>Smart Cart Product Detection</h1>
      <SmartCart />
    </div>
  );
}

export default App;